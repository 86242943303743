var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"editorPane",staticClass:"editor-pane",on:{"click":_vm.handleClickCanvas}},[_c('div',{staticClass:"components-contrl-bar"},[_c('el-tooltip',{attrs:{"effect":"dark","content":"保存预览","placement":"right"}},[_c('div',{staticClass:"button-item",on:{"click":_vm.showPreview}},[_c('i',{staticClass:"iconfont iconbaocun"})])]),_c('el-tooltip',{attrs:{"effect":"dark","content":"撤销","placement":"right"}},[_c('div',{staticClass:"button-item",class:{disabled: !_vm.canUndo},on:{"click":_vm.editorUndo}},[_c('i',{staticClass:"iconfont iconundo1"})])]),_c('el-tooltip',{attrs:{"effect":"dark","content":"重做","placement":"right"}},[_c('div',{staticClass:"button-item",class:{disabled: !_vm.canRedo},on:{"click":_vm.editorRedo}},[_c('i',{staticClass:"iconfont iconredo1"})])]),_c('el-tooltip',{attrs:{"effect":"dark","content":"放大","placement":"right"}},[_c('div',{staticClass:"button-item",on:{"click":function($event){return _vm.updateScale('plus')}}},[_c('i',{staticClass:"el-icon-zoom-in"})])]),_c('el-input',{staticClass:"scale-input",attrs:{"size":"mini","placeholder":"请输入内容"},model:{value:(_vm.scale),callback:function ($$v) {_vm.scale=$$v},expression:"scale"}}),_c('el-tooltip',{attrs:{"effect":"dark","content":"缩小","placement":"right"}},[_c('div',{staticClass:"button-item",on:{"click":function($event){return _vm.updateScale('reduce')}}},[_c('i',{staticClass:"el-icon-zoom-out"})])])],1),_c('div',{staticClass:"editor-pane-inner"},[_c('div',{staticClass:"editor-main",style:(_vm.getCommonStyle({
				width: _vm.activePage.config.long?(_vm.activePage.config.direction == 1?(_vm.activePage.commonStyle.width):_vm.width):_vm.width,
				height: _vm.activePage.config.long?(_vm.activePage.config.direction == 0?(_vm.activePage.commonStyle.height):_vm.height):_vm.height
			}, _vm.scale/100))},[_c('div',{ref:"canvas-panel",staticClass:"page-preview-wrapper",style:(_vm.getCommonStyle({..._vm.activePage.commonStyle}, _vm.scale/100)),attrs:{"id":"canvas-panel"}},[(_vm.editMode == 'pages' && (!_vm.activePage.config.long || _vm.activePage.config.direction == 1))?[_c('div',{staticClass:"phone-screen",style:(_vm.phoneScreenStyle(505))},[_vm._m(0),_c('div',{staticStyle:{"border-top":"2px dashed rgb(194, 194, 194)"}})]),_c('div',{staticClass:"phone-screen",style:(_vm.phoneScreenStyle(_vm.height))},[_c('div',{staticClass:"tips"},[_vm._v(_vm._s(_vm.height*2)+"px"),_c('br'),_vm._v("全面屏")]),_c('div',{staticStyle:{"border-top":"2px dashed rgb(194, 194, 194)"}})])]:_vm._e(),_vm._l((_vm.activePage.elements),function(item){return _c('edit-shape',{key:item.uuid,style:(_vm.getCommonStyle({
						top: item.commonStyle.top,
						left: item.commonStyle.left,
						width: item.commonStyle.width,
						height: item.commonStyle.height,
						position: item.commonStyle.position
					}, _vm.scale/100)),attrs:{"uuid":item.uuid,"config":item.config,"active":item.uuid === _vm.activeElementUUID,"defaultStyle":item.commonStyle},on:{"handleElementClick":function($event){return _vm.handleElementClick(item.uuid)},"resize":_vm.handleElementResize}},[_c(item.elName,_vm._b({tag:"component",staticClass:"element-on-edit-pane",style:(_vm.getCommonStyle({...item.commonStyle, top: 0, left: 0}, _vm.scale/100))},'component',item.propsValue,false))],1)})],2),_c('div',{staticClass:"page-wrapper-mask"})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips"},[_vm._v("1010px"),_c('br'),_vm._v("常规屏")])
}]

export { render, staticRenderFns }