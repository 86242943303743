var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scrollbar-wrapper",staticStyle:{"height":"100%","padding-bottom":"60px"}},[_c('el-scrollbar',{staticStyle:{"height":"100%"}},[(_vm.projectData[_vm.mode] && _vm.projectData[_vm.mode].length)?_vm._l((_vm.projectData[_vm.mode]),function(item,index){return _c('div',{key:item.uuid,staticClass:"page-item",class:{active: _vm.activePageUUID === item.uuid},attrs:{"draggable":_vm.draggable},on:{"click":function($event){return _vm.changeActivePage(item)},"dragover":function($event){$event.preventDefault();return _vm.handleDragOver($event, item)},"dragstart":function($event){return _vm.handleDragStart($event, item)},"dragenter":function($event){return _vm.handleDragEnter($event, item)},"dragend":function($event){return _vm.handleDragEnd($event, item)}}},[_c('div',{staticClass:"page-item-index"},[_vm._v(_vm._s(index + 1))]),_c('div',{staticStyle:{"width":"100px","height":"140px","border":"1px solid #f1f1f1","overflow":"hidden"}},[_c('div',{style:(_vm.getCommonStyle({...item.commonStyle, position: 'relative', width: '340', height: '620'}, 100/340))},_vm._l((item.elements),function(element){return _c('div',{key:element.uuid,style:(_vm.getCommonStyle({
								top: element.commonStyle.top,
								left: element.commonStyle.left,
								width: element.commonStyle.width,
								height: element.commonStyle.height,
								position: element.commonStyle.position
							}, 100/340)),attrs:{"uuid":element.uuid,"defaultStyle":element.commonStyle}},[_c(element.elName,{tag:"component",style:(_vm.getCommonStyle({...element.commonStyle, top: 0, left: 0}, 100/340))})],1)}),0)]),_c('div',{staticClass:"page-item-btn-wrapper"},[(_vm.mode == 'pages')?_c('el-tooltip',{attrs:{"effect":"dark","content":item.config.hide?'显示页面':'隐藏页面',"placement":"right"}},[_c('div',{staticClass:"btn-item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.hidePage(item)}}},[_c('i',{class:item.config.hide?'el-icon-remove-outline':'el-icon-view'})])]):_vm._e(),_c('el-tooltip',{attrs:{"effect":"dark","content":"复制页面","placement":"right"}},[_c('div',{staticClass:"btn-item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyPage(item)}}},[_c('i',{staticClass:"el-icon-document-copy"})])]),_c('el-tooltip',{attrs:{"effect":"dark","content":"添加页面","placement":"right"}},[_c('div',{staticClass:"btn-item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.addPage(item)}}},[_c('i',{staticClass:"el-icon-document-add"})])]),_c('el-tooltip',{attrs:{"effect":"dark","content":"删除页面","placement":"right"}},[_c('div',{staticClass:"btn-item",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.deletePage(item)}}},[_c('i',{staticClass:"el-icon-delete"})])])],1)])}):_c('p',{staticStyle:{"color":"#666","text-align":"center"}},[_vm._v("暂无数据")])],2),_c('div',{staticClass:"bottom-btn-wrapper"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.addPage()}}},[_vm._v("新增页面")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }